<template>
  <v-container fluid class="pt-0">
    <MainModal
        :main="{ component: 'BasesByService', title: 'Налаштування здачі бази' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_bases_by_service)"
        :modal="show_bases_by_service_dialog"
        :nonClickable="false"
        @updateItemModal="basesByServiceUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'PaymentsByPriority', title: 'Налаштування розподілу оплат за пріоритетом' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_payments_by_priority)"
        :modal="show_payments_by_priority_dialog"
        :nonClickable="false"
        @updateItemModal="paymentsByPriorityUpdateItemModal"
    />
    <v-row justify="center">
      <v-col cols="12">
        <v-card tile elevation="0" class="fill-height" min-height="280">
          <v-card-text class="pa-0">
            <v-tabs class="custom-tabs" color="success" v-model="tab">
              <v-tab class="text-left justify-start" v-for="(page, idx) in pages" :key="idx">
                {{ getPageTranslate(page) }}
              </v-tab>

              <v-tab-item class="pa-0" v-for="(page, idx) in pages" :key="idx">
                <v-row>
                  <v-col cols="12">
                    <v-card tile elevation="2" class="fill-height pa-3" style="border-left: 3px solid #5bb55f" min-height="280">
                      <v-row>
                        <template v-for="(item, index) in getPageKeys(page)">
                          <template v-if="item.short">
                            <template v-if="item.type === 'id'">
                              <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                <v-select
                                    :label="item.print_name"
                                    :items.sync="getItemsForSelect[item.table_name]"
                                    v-model="item.value"
                                    hide-details
                                    filled
                                    color="grey darken-2"
                                    clearable
                                />
                              </v-col>
                            </template>
                            <template v-if="item.type === 'int'">
                              <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                <v-text-field
                                    :label="item.print_name"
                                    v-model="item.value"
                                    v-integer
                                    hide-details
                                    filled
                                    color="grey darken-2"
                                    clearable
                                />
                              </v-col>
                            </template>
                            <template v-if="item.type === 'string' && item.client_func">
                              <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                <v-select
                                    :label="item.print_name"
                                    :items.sync="getItemsForSelect[item.client_func]"
                                    v-model="item.value"
                                    hide-details
                                    filled
                                    color="grey darken-2"
                                    clearable
                                />
                              </v-col>
                            </template>
                            <template v-if="item.type === 'string' && !item.client_func">
                              <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                <v-text-field
                                    :label="item.print_name"
                                    v-model="item.value"
                                    hide-details
                                    filled
                                    color="grey darken-2"
                                    clearable
                                />
                              </v-col>
                            </template>
                            <template v-if="item.type === 'bool'">
                              <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                <v-switch
                                    v-model="item.value"
                                    hide-details
                                    :label="item.value ? `${item.print_name} (включено)` : `${item.print_name} (відключено)`"
                                    :disabled="getRelatedValue(item.related_name)"
                                    color="success"
                                >
                                </v-switch>
                              </v-col>
                            </template>
                            <template v-if="item.type === 'date'">
                              <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                <template v-if="item.name === 'penalty_debt_month'">
                                  <date-component
                                      :label="item.print_name"
                                      v-model="item.value"
                                      :req="penaltyCharge"
                                      :class_="penaltyCharge && !item.value ? 'req-star' : ''"
                                      filled
                                  />
                                </template>
                                <template v-else-if="item.name === 'penalty_charge_date_start'">
                                  <date-component
                                      :label="item.print_name"
                                      v-model="item.value"
                                      :req="penaltyCharge"
                                      :class_="penaltyCharge && !item.value ? 'req-star' : ''"
                                      filled
                                  />
                                </template>
                                <template v-else>
                                  <date-component
                                      :label="item.print_name"
                                      v-model="item.value"
                                      filled
                                  />
                                </template>
                              </v-col>
                            </template>
                          </template>
                          <template v-else>
                            <v-col cols="12" :key="`row-left-and-right-${index}`">
                              <v-row>
                                <template v-if="item.type === 'id'">
                                  <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <v-select
                                        :label="item.print_name"
                                        :items.sync="getItemsForSelect[item.table_name]"
                                        v-model="item.value"
                                        hide-details
                                        filled
                                        color="grey darken-2"
                                        clearable
                                    />
                                  </v-col>
                                  <v-col cols="12" md="6" :key="`right-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <div class="notification-description">
                                      <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                      {{ item.description }}
                                    </div>
                                  </v-col>
                                </template>
                                <template v-if="item.type === 'int'">
                                  <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <v-text-field
                                        :label="item.print_name"
                                        v-model="item.value"
                                        v-integer
                                        hide-details
                                        filled
                                        color="grey darken-2"
                                        clearable
                                    />
                                  </v-col>
                                  <v-col cols="12" md="6" :key="`right-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <div class="notification-description">
                                      <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                      {{ item.description }}
                                    </div>
                                  </v-col>
                                </template>
                                <template v-if="item.type === 'string' && item.client_func">
                                  <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <v-select
                                        :label="item.print_name"
                                        :items.sync="getItemsForSelect[item.client_func]"
                                        v-model="item.value"
                                        hide-details
                                        filled
                                        color="grey darken-2"
                                        clearable
                                    />
                                  </v-col>
                                  <v-col cols="12" md="6" :key="`right-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <div class="notification-description">
                                      <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                      {{ item.description }}
                                    </div>
                                  </v-col>
                                </template>
                                <template v-if="item.type === 'string' && !item.client_func">
                                  <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <v-text-field
                                        :label="item.print_name"
                                        v-model="item.value"
                                        hide-details
                                        filled
                                        color="grey darken-2"
                                        clearable
                                    />
                                  </v-col>
                                  <v-col cols="12" md="6" :key="`right-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <div class="notification-description">
                                      <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                      {{ item.description }}
                                    </div>
                                  </v-col>
                                </template>
                                <template v-if="item.type === 'bool'">
                                  <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <v-switch
                                        v-model="item.value"
                                        hide-details
                                        :label="item.value ? `${item.print_name} (включено)` : `${item.print_name} (відключено)`"
                                        :disabled="getRelatedValue(item.related_name)"
                                        color="success"
                                    >
                                    </v-switch>
                                  </v-col>
                                  <v-col cols="12" md="6" :key="`right-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <div class="notification-description">
                                      <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                      {{ item.description }}
                                    </div>
                                  </v-col>
                                </template>
                                <template v-if="item.type === 'date'">
                                  <v-col cols="12" md="6" :key="`left-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <template v-if="item.name === 'penalty_debt_month'">
                                      <date-component
                                          :label="item.print_name"
                                          v-model="item.value"
                                          :req="penaltyCharge"
                                          :class_="penaltyCharge && !item.value ? 'req-star' : ''"
                                          filled
                                      />
                                    </template>
                                    <template v-else-if="item.name === 'penalty_charge_date_start'">
                                      <date-component
                                          :label="item.print_name"
                                          v-model="item.value"
                                          :req="penaltyCharge"
                                          :class_="penaltyCharge && !item.value ? 'req-star' : ''"
                                          filled
                                      />
                                    </template>
                                    <template v-else>
                                      <date-component
                                          :label="item.print_name"
                                          v-model="item.value"
                                          filled
                                      />
                                    </template>
                                  </v-col>
                                  <v-col cols="12" md="6" :key="`right-${index}`" :class="index === 0 ? 'pt-3 pb-1' : 'py-1'">
                                    <div class="notification-description">
                                      <v-icon size="32" color="success" class="mr-4">mdi-information</v-icon>
                                      {{ item.description }}
                                    </div>
                                  </v-col>
                                </template>
                              </v-row>
                            </v-col>
                          </template>
                        </template>
                        <template v-if="page === 'd_bases'">
                          <v-col cols="12">
                            <v-card :disabled="baseGeneral">
                              <v-card-text>
                                <v-toolbar elevation="0" class="pa-0">
                                  <v-toolbar-title>
                                    Налаштування здачі бази по послугах
                                  </v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-tooltip bottom color="success">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon @click="openBasesByServiceCreateDialog"
                                             v-bind="attrs"
                                             v-on="on"
                                             class="grey lighten-4 mr-1">
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Створити налаштування здачі бази по послугах</span>
                                  </v-tooltip>
                                </v-toolbar>
                                <v-data-table
                                    :headers="basesHeader"
                                    :items="bases_by_service"
                                    hide-default-footer
                                    class="custom-table custom-table-1"
                                    @click:row="onBasesByServiceItemClick"
                                >
                                  <template v-slot:item.icon>
                                    <v-icon size="26" color="success" class="mr-2">
                                      mdi-account-arrow-right-outline
                                    </v-icon>
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </template>
                        <template v-if="page === 'payments'">
                          <v-col cols="12">
                            <v-card :disabled="!splitPaymentsByPriority">
                              <v-card-text>
                                <v-toolbar elevation="0" class="pa-0">
                                  <v-toolbar-title>
                                    Налаштування розподілу оплат за пріорітетом
                                  </v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-tooltip bottom color="success">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn icon @click="openPaymentsByPriorityCreateDialog"
                                             v-bind="attrs"
                                             v-on="on"
                                             class="grey lighten-4 mr-1">
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Створити налаштування розподілу оплат за пріоритетом</span>
                                  </v-tooltip>
                                </v-toolbar>
                                <v-data-table
                                    :headers="paymentsHeader"
                                    :items="payments_by_priority"
                                    hide-default-footer
                                    class="custom-table custom-table-1"
                                    @click:row="onPaymentsByPriorityItemClick"
                                >
                                  <template v-slot:item.icon>
                                    <v-icon size="26" color="success" class="mr-2">
                                      mdi-account-arrow-right-outline
                                    </v-icon>
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </template>
                        <v-col cols="6">
                          <v-btn block depressed color="grey lighten-2" @click="saveSettings">
                            Зберегти
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {FETCH_FLAT_INDICATOR_TYPES} from "@/store/actions/flat_indicators";
import {SELECT_SMS_SEND_CONFIG} from "@/store/actions/sms_send_config"
import {SELECT_SMS_SEND_TEMPLATE} from "@/store/actions/sms_send_template"
import {SELECT_SERVICES} from "@/store/actions/services";
import organizationAPI from "@/utils/axios/organization"
import {desktop_tree_type_select, flat_ownership_types_select, split_type_select} from "@/utils/icons"
import {ALERT_SHOW} from "@/store/actions/alert";
import {FETCH_BASES_BY_SERVICE, FETCH_PAYMENTS_BY_PRIORITY, GET_GENERAL_SETTINGS} from "@/store/actions/organization";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GeneralSettings",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  data() {
    return {
      settings: {},
      selects_items: {},
      pages: [],
      tab: 0,
      basesHeader: [
        { text: '', value: 'icon', width: 60 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Послуга (згорнути)', value: 'service_collapse_name' },
      ],
      paymentsHeader: [
        { text: '', value: 'icon', width: 60 },
        { text: 'Послуга', value: 'service_name' },
        { text: 'Пріорітет', value: 'priority' },
      ],
      modal_id: 0,
      payments_modal_id: 0,

      selected_bases_by_service: {},
      show_bases_by_service_dialog: false,

      selected_payments_by_priority: {},
      show_payments_by_priority_dialog: false
    }
  },
  computed: {
    ...mapGetters({
      bases_by_service: 'getBasesByService',
      payments_by_priority: 'getPaymentsByPriority'
    }),
    getItemsForSelect() {
      return this.selects_items
    },
    baseGeneral() {
      return this.settings?.bases_general?.value || false
    },
    penaltyCharge() {
      return this.settings?.penalty_charge?.value || false
    },
    splitPaymentsByPriority() {
      return this.settings?.split_payments_by_priority?.value || false
    }
  },
  methods: {
    ...mapActions({
      fetchBases: FETCH_BASES_BY_SERVICE,
      fetchPayments: FETCH_PAYMENTS_BY_PRIORITY
    }),
    getPageTranslate(page) {
      const pages = {
        'general': 'Загальні',
        'work_place': 'Робоче місце',
        'payments': 'Оплати',
        'person_account': 'Особові рахунки',
        'person_cabinet': 'Персональний кабінет',
        'counter': 'Лічильники',
        'desktop': 'Робочий стіл',
        'penalty': 'Пеня',
        'restructuring': 'Реструктуризація',
        'push_notification': 'Оповіщення',
      }
      return pages[page] || 'Інші'
    },
    getPageKeys(page) {
      const page_keys = []
      Object.keys(this.settings).forEach(item => {
        const value = this.settings[item]
        if (value.page === page) {
          page_keys.push(value)
        }
      })
      return page_keys
    },
    getItems(payload) {
      if (payload.type === 'id') {
        if (payload.table_name === 'flat_indicator_types') {
          if (this.selects_items.flat_indicator_types) {
            return
          }
          this.$store.dispatch(FETCH_FLAT_INDICATOR_TYPES)
              .then(res => {
                if (res) {
                  this.$set(this.selects_items, 'flat_indicator_types', this.$store.state.flat_indicator.flat_indicator_types)
                }
              })
        }
        if (payload.table_name === 'service') {
          if (this.selects_items.service) {
            return
          }

          this.$store.dispatch(SELECT_SERVICES)
              .then(res => {
                if (res) {
                  this.$set(this.selects_items, 'service', this.$store.state.service.services_select)
                }
              })
        }
        if (payload.table_name === 'sms_send_config') {
          if (this.selects_items.sms_send_config) {
            return
          }
          this.$store.dispatch(SELECT_SMS_SEND_CONFIG)
              .then(res => {
                if (res) {
                  this.$set(this.selects_items, 'sms_send_config', this.$store.state.sms_send_config.select_sms_send_configs)
                }
              })
        }
        if (payload.table_name === 'sms_send_template') {
          if (this.selects_items.sms_send_template) {
            return
          }
          this.$store.dispatch(SELECT_SMS_SEND_TEMPLATE)
              .then(res => {
                if (res) {
                  this.$set(this.selects_items, 'sms_send_template', this.$store.state.sms_send_template.select_sms_send_templates)
                }
              })
        }
      }

      if (payload.client_func) {
        if (this.selects_items[payload.client_func]) {
          return
        }

        if (payload.client_func === 'flat_ownership_types_select') {
          this.$set(this.selects_items, payload.client_func, flat_ownership_types_select)
        }

        if (payload.client_func === 'split_type_select') {
          this.$set(this.selects_items, payload.client_func, split_type_select)
        }

        if (payload.client_func === 'desktop_tree_type_select') {
          this.$set(this.selects_items, payload.client_func, desktop_tree_type_select)
        }
      }
    },
    getRelatedValue(col) {
      if (!col) {
        return false
      }
      return !this.settings[col]['value']
    },
    saveSettings() {
      organizationAPI.save_general_settings(this.settings)
        .then(res => {
          if (res) {
            this.$store.dispatch(ALERT_SHOW, { text: 'Налаштування збережені успіщно', color: 'success' })
            this.$store.dispatch(GET_GENERAL_SETTINGS)
            this.$router.push({ name: 'Desktop' })
          }
        })
        .catch(err => {
          const error = err.response.data.detail;
          this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
        })
    },

    basesByServiceUpdateItemModal() {
      this.show_bases_by_service_dialog = false
      this.selected_bases_by_service = {}
    },
    openBasesByServiceCreateDialog() {
      this.selected_bases_by_service = {}
      this.show_bases_by_service_dialog = true
    },
    onBasesByServiceItemClick(payload) {
      this.selected_bases_by_service = JSON.parse(JSON.stringify(payload))
      this.show_bases_by_service_dialog = true
    },

    paymentsByPriorityUpdateItemModal() {
      this.show_payments_by_priority_dialog = false
      this.selected_payments_by_priority = {}
    },
    openPaymentsByPriorityCreateDialog() {
      this.selected_payments_by_priority = {}
      this.show_payments_by_priority_dialog = true
    },
    onPaymentsByPriorityItemClick(payload) {
      this.selected_payments_by_priority = JSON.parse(JSON.stringify(payload))
      this.show_payments_by_priority_dialog = true
    },
  },
  created() {
    organizationAPI.get_general_settings()
      .then(response => response.data)
      .then(data => {
        this.settings = data

        Object.keys(this.settings).forEach(item => {
          const value = this.settings[item]
          this.getItems(value)
          if (!this.pages.includes(value.page)) {
            this.pages.push(value.page)
          }
        })
        this.fetchBases()
        this.fetchPayments()
      })
      .catch(err => {
        const error = err.response.data.detail;
        this.$store.dispatch(ALERT_SHOW, { text: error, color: 'error lighten-1' })
      })
  }
}
</script>

<style scoped lang="scss">
  .notification-description {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: #f9fff6;
    height: 100%;
    border: 2px solid #e0f2d6;
    position: relative;
    color: #2d2d2d;
  }
  .custom-tabs {
    :deep(.v-window.v-item-group.theme--light) {
      background-color: transparent;
    }

    :deep(div[role="tablist"]) {
      background-color: #f5f5f5 !important;
    }
  }
</style>
